import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { hexToRgba } from "./utils/HexToRgba";
export const tokens = (mode: PaletteMode) => ({
  // Color tokens
  ...(mode === "dark"
    ? {
        button: {
          default: "#141f3f",
          hover: "#0c1329",
        },
        slate: {
          50: "#f8fafc",
          100: "#f1f5f9",
          200: "#e2e8f0",
          300: "#cbd5e1",
          400: "#94a3b8",
          500: "#64748b",
          600: "#475569",
          700: "#334155",
          800: "#1e293b",
          900: "#0f172a",
          950: "#020617",
        },
        gray: {
          50: "#f9fafb",
          100: "#f3f4f6",
          200: "#e5e7eb",
          300: "#d1d5db",
          400: "#9ca3af",
          500: "#6b7280",
          600: "#4b5563",
          700: "#374151",
          800: "#1f2937",
          900: "#111827",
          950: "#030712",
        },

        red: {
          50: "#fef2f2",
          100: "#fee2e2",
          200: "#fecaca",
          300: "#fca5a5",
          400: "#f87171",
          500: "#ef4444",
          600: "#dc2626",
          700: "#b91c1c",
          800: "#991b1b",
          900: "#7f1d1d",
          950: "#450a0a",
        },
        orange: {
          50: "#fff7ed",
          100: "#ffedd5",
          200: "#fed7aa",
          300: "#fdba74",
          400: "#fb923c",
          500: "#f97316",
          600: "#ea580c",
          700: "#c2410c",
          800: "#9a3412",
          900: "#7c2d12",
          950: "#431407",
        },

        yellow: {
          50: "#fefce8",
          100: "#fef9c3",
          200: "#fef08a",
          300: "#fde047",
          400: "#facc15",
          500: "#eab308",
          600: "#ca8a04",
          700: "#a16207",
          800: "#854d0e",
          900: "#713f12",
          950: "#422006",
        },

        green: {
          50: "#f0fdf4",
          100: "#dcfce7",
          200: "#bbf7d0",
          300: "#86efac",
          400: "#4ade80",
          500: "#22c55e",
          600: "#16a34a",
          700: "#15803d",
          800: "#166534",
          900: "#14532d",
          950: "#052e16",
        },

        blue: {
          50: "#eff6ff",
          100: "#dbeafe",
          200: "#bfdbfe",
          300: "#93c5fd",
          400: "#60a5fa",
          500: "#3b82f6",
          600: "#2563eb",
          700: "#1d4ed8",
          800: "#1e40af",
          900: "#1e3a8a",
          950: "#172554",
        },

        fuchsia: {
          50: "#fdf4ff",
          100: "#fae8ff",
          200: "#f5d0fe",
          300: "#f0abfc",
          400: "#e879f9",
          500: "#d946ef",
          600: "#c026d3",
          700: "#a21caf",
          800: "#86198f",
          900: "#701a75",
          950: "#4a044e",
        },
      }
    : {
        button: {
          default: "#d8e1fa",
          hover: "#e8eefa",
        },
        slate: {
          50: "#020617",
          100: "#0f172a",
          200: "#1e293b",
          300: "#334155",
          400: "#475569",
          500: "#64748b",
          600: "#94a3b8",
          700: "#cbd5e1",
          800: "#e2e8f0",
          900: "#f1f5f9",
          950: "#f8fafc",
        },
        gray: {
          50: "#030712",
          100: "#111827",
          200: "#1f2937",
          300: "#374151",
          400: "#4b5563",
          500: "#6b7280",

          600: "#9ca3af",
          700: "#d1d5db",
          800: "#e5e7eb",
          900: "#f3f4f6",
          950: "#f9fafb",
        },

        red: {
          50: "#450a0a",
          100: "#7f1d1d",
          200: "#991b1b",
          300: "#b91c1c",
          400: "#dc2626",
          500: "#ef4444",
          600: "#f87171",
          700: "#fca5a5",
          800: "#fecaca",
          900: "#fee2e2",
          950: "#fef2f2",
        },
        orange: {
          50: "#431407",
          100: "#7c2d12",
          200: "#9a3412",
          300: "#c2410c",
          400: "#ea580c",
          500: "#f97316",
          600: "#fb923c",
          700: "#fdba74",
          800: "#fed7aa",
          900: "#ffedd5",
          950: "#fff7ed",
        },

        yellow: {
          50: "#422006",
          100: "#713f12",
          200: "#854d0e",
          300: "#a16207",
          400: "#ca8a04",
          500: "#eab308",
          600: "#facc15",
          700: "#fde047",
          800: "#fef08a",
          900: "#fef9c3",
          950: "#fefce8",
        },

        green: {
          50: "#052e16",
          100: "#14532d",
          200: "#166534",
          300: "#15803d",
          400: "#16a34a",
          500: "#22c55e",
          600: "#4ade80",
          700: "#86efac",
          800: "#bbf7d0",
          900: "#dcfce7",
          950: "#f0fdf4",
        },

        blue: {
          50: "#172554",
          100: "#1e3a8a",
          200: "#1e40af",
          300: "#1d4ed8",
          400: "#2563eb",
          500: "#3b82f6",
          600: "#60a5fa",
          700: "#93c5fd",
          800: "#bfdbfe",
          900: "#dbeafe",
          950: "#eff6ff",
        },

        fuchsia: {
          50: "#4a044e",
          100: "#701a75",
          200: "#86198f",
          300: "#a21caf",
          400: "#c026d3",
          500: "#d946ef",
          600: "#e879f9",
          700: "#f0abfc",
          800: "#f5d0fe",
          900: "#fae8ff",
          950: "#fdf4ff",
        },
      }),
});
declare module "@mui/material/styles" {
  interface Palette {
    border: Palette["primary"];
    textColor: Palette["primary"];
    toastColor: Palette["primary"];
    button: any;
  }
  interface PaletteOptions {
    border: PaletteOptions["primary"];
    textColor: PaletteOptions["primary"];
    toastColor: PaletteOptions["primary"];
    button: any;
  }
}
// mui theme settings
export const themeSettings: any = (mode: any) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,

      primary: {
        main: colors.slate[900],
        dark: colors.slate[800],
        light: colors.slate[700],
        contrastText: colors.slate[200],
      },
      secondary: {
        main: colors.slate[900],
        light: colors.button.default,
        dark: colors.slate[950],
      },
      error: {
        // Hata Rengi
        main: colors.red[500],
        dark: colors.red[900],
        light: colors.red[200],
      },
      warning: {
        // Uyarı Rengi
        main: colors.yellow[500],
      },
      success: {
        // Başarı Rengi
        main: colors.green[500],
      },
      info: {
        // Bilgi Rengi
        main: colors.blue[500],
        light: colors.blue[300],
        dark: colors.blue[700],
      },
      background: {
        default: colors.gray[950],
        paper: colors.gray[900],
        light: colors.gray[200],
      },
      border: {
        main: colors.slate[700],
      },
      button: {
        main: colors.button.default,
        hover: colors.button.hover,
      },
      textColor: {
        main: colors.slate[200],
        light: colors.slate[400],
        dark: colors.slate[100],
      },
      toastColor: {
        main: colors.gray[800],
        contrastText: colors.gray[200],
      },
    },
    components: {
      // Mui Text Field
      MuiTextField: {
        styleOverrides: {
          root: {
            "& input": {
              color: colors.slate[100],
            },
            "& label": {
              color: colors.slate[400],
            },
            "& label.Mui-focused": {
              color: colors.blue[500],
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.slate[600],
              },
              "&:hover fieldset": {
                borderColor: colors.blue[400],
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.blue[500],
              },
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: colors.blue[500], // Solid underline on focus
            },
          },
        },
      },
      // Mui typography
      MuiTypography: {
        defaultProps: {
          fontFamily: "Public Sans",
          color: colors.slate[200],
        },
      },
      // Mui icon button
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: `${colors.blue[800]}50`, // Icon Button Background Color
            },
          },
        },
      },
      // Mui svg icon
      MuiSvgIcon: {
        defaultProps: {
          style: {
            color: colors.blue[500], // Icon Color
          },
        },
      },
      // Mui switch
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Renkli kısmın varsayılan hali (kapalı)
            color: colors.slate[200],
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Açıkken ilk renk
              color: colors.blue[500],
            },
          },
          track: {
            // Kapalıyken arka plan rengi
            backgroundColor: colors.slate[200],
            ".Mui-checked.Mui-checked + &": {
              // Açıkken arka plan rengi
              backgroundColor: colors.blue[500],
            },
          },
        },
      },
      // Mui Scrollbar
      MuiCssBaseline: {
        styleOverrides: {
          "&::-webkit-scrollbar": {
            width: "16px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.slate[600],
            borderRadius: "20px",
            border: "6px solid transparent",
            backgroundClip: "content-box",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: colors.slate[500],
          },
        },
      },
      // Mui Datagrid
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            overflow: "hidden",
          },
          cell: {
            backgroundColor: colors.gray[950],
            ":focus": {
              outline: "none",
            },
          },
          columnHeaderRow: {
            backgroundColor: `${colors.button.hover} !important`,
          },
          columnHeaderTitle: {
            fontWeight: "700",
          },
          footerContainer: {
            justifyContent: "normal !important",
          },
        },
      },
      // Mui Button
      MuiButton: {
        styleOverrides: {
          root: {
            color: colors.slate[200],
            backgroundColor: colors.button.default,
            ":hover": {
              backgroundColor: colors.button.hover,
            },
            ":disabled": {
              backgroundColor: colors.gray[600],
            },
          },
        },
      },
      // MuiSelect
      MuiSelect: {
        styleOverrides: {
          select: {
            borderColor: "red",
            "&:focus": {
              borderColor: colors.blue[500], // Tıklanıldığında kenarlık rengi
            },
          },
        },
      },
    },
    typography: {
      fontSize: 12,
      fontFamily: "Public Sans",
      color: colors.slate[200],
      h1: {
        fontSize: 40,
        fontFamily: "Public Sans",
        color: colors.slate[200],
      },
      h2: {
        fontSize: 32,
        fontFamily: "Public Sans",
        color: colors.slate[200],
      },
      h3: {
        fontSize: 24,
        fontFamily: "Public Sans",
        color: colors.slate[200],
      },
      h4: {
        fontSize: 20,
        fontFamily: "Public Sans",
        color: colors.slate[200],
      },
      h5: {
        fontSize: 16,
        fontFamily: "Public Sans",
        color: colors.slate[200],
      },
      h6: {
        fontSize: 14,
        fontFamily: "Public Sans",
        color: colors.slate[200],
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState<PaletteMode>("dark");

  // localStorage'dan kaydedilmiş moda erişmek için useEffect kullanıyoruz
  useEffect(() => {
    const storedMode = localStorage.getItem("mode") as PaletteMode | null;
    if (storedMode && (storedMode === "light" || storedMode === "dark")) {
      setMode(storedMode);
    }
  }, []); // Sadece ilk renderda çalışacak

  const toggleColorMode = () => {
    setMode((prev) => {
      const newMode = prev === "light" ? "dark" : "light";
      localStorage.setItem("mode", newMode); // Yeni modu localStorage'a kaydet
      return newMode;
    });
  };
  const colorMode = useMemo(
    () => ({
      toggleColorMode,
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
