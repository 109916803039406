interface ILocations {
  id: number;
  community: string;
  description: string;
  expire: string;
  nasname: string;
  shortname: string;
  sms_balance: number;
}

interface AuthData {
  locations: ILocations[];
  user: {};
}

interface AuthModel {
  is_true: boolean;
  access_token: string;
  server_url: string;
  data: AuthData;
  expire: string;
}
const AUTH_LOCAL_STORAGE_KEY = "user";
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    // auth.access_token auth.data, auth.data.locations ve auth.data.locations.length kontrolleri
    if (
      !auth.access_token ||
      auth.access_token.length < 1 ||
      !auth.data ||
      !auth.data.locations ||
      auth.data.locations.length < 1
    ) {
      removeAuth();
      return;
    }
    // expired kontrolü
    if (new Date(auth.expire) < new Date()) {
      // Auth_token expiration
      removeAuth();
      return;
    }
    return auth;
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
