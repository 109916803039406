import React, { useContext, useState } from "react";
import * as yup from "yup";
import {
  Alert,
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "./AuthProvider";
import { ColorModeContext, tokens } from "../theme";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import CustomInput from "../components/CustomInput";
import {
  ErrorOutlineOutlined,
  LocalFireDepartment,
  LanguageOutlined,
  DarkModeOutlined,
  LightModeOutlined,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { LanguageContext } from "../language/LanguageContext";
import HeaderLanguage from "../layout/topbar/HeaderLanguage";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSubmitButton from "../components/buttons/CustomSubmitButton";

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { texts } = useContext(LanguageContext);

  const [isPosting, setIsPosting] = useState(false);
  const [validatorError, setValidatorError] = useState("");

  const { saveAuth, setUser } = useAuth();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    remember: yup.boolean(),
  });

  const keyFrames = {
    "0%": {
      transform: "translate(0px,0px) scale(1)",
    },
    "33%": {
      transform: "translate(15%,-25%) scale(1.1)",
    },
    "66%": {
      transform: "translate(-10%,15%) scale(0.9)",
    },
    "100%": {
      transform: "translate(0px,0px) scale(1)",
    },
  };

  // Language menu
  const [languageState, setLanguageState] = useState<null | HTMLElement>(null);
  const openLanguage = Boolean(languageState);
  const idLanguage = openLanguage ? "simple-popper" : undefined;

  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageState(event.currentTarget);
  };
  const handleLanguageClickAway = () => {
    setLanguageState(null);
  };
  // hex to rgba converter
  const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  // Show/hide password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
      remember: false,
    },
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit } = methods;

  const onSubmitHandler = async (data: any) => {
    setValidatorError("");
    setIsPosting(true);
    try {
      const loginUrl = process.env.REACT_APP_LOGIN_URL;

      if (!loginUrl) {
        throw new Error(
          "REACT_APP_LOGIN_URL is not defined in the environment variables."
        );
      }
      const response = await axios.post(loginUrl, data);
      if (response.data.is_success === true) {
        // Expire date için
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 3);
        currentDate.setMinutes(currentDate.getMinutes() + 50);
        // Tarih formatını ayarlamak için toLocaleString kullanın
        const formattedDate = currentDate.toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
        const formattedAuth = { ...response.data, expire: formattedDate };
        saveAuth(formattedAuth);
        setUser(formattedAuth);
      } else {
        setValidatorError(response.data.validator_errors.email[0]);
      }
      setIsPosting(false);
    } catch (error) {
      console.error("error", error);
      saveAuth(undefined);
      setIsPosting(false);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            backgroundColor: hexToRgba(theme.palette.primary.main, 1),
            overflow: "hidden",
          }}
        >
          {/* SIGN-IN */}
          <Grid
            item
            xs={12}
            md={6}
            component={Paper}
            elevation={24}
            sx={{
              backgroundColor: `${theme.palette.background.default}90`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 3,
              zIndex: 2,
              position: "relative",
              backgroundImage: "none",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 20,
                top: 20,
                display: "flex",
                gap: 2,
              }}
            >
              {/* Language */}
              <ClickAwayListener onClickAway={handleLanguageClickAway}>
                <Box>
                  <IconButton
                    id="basic-button"
                    onClick={handleClickLanguage}
                    sx={{
                      backgroundColor: "primary.dark",
                    }}
                  >
                    <LanguageOutlined />
                  </IconButton>
                  <HeaderLanguage
                    open={openLanguage}
                    id={idLanguage}
                    anchorEl={languageState}
                    setAnchorEl={setLanguageState}
                  />
                </Box>
              </ClickAwayListener>
              {/* Dark/Light Mode */}
              <IconButton
                sx={{
                  backgroundColor: "primary.dark",
                }}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlined />
                ) : (
                  <LightModeOutlined />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: { xs: 20, sm: 28 },
                  pb: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {texts.welcome_msg}
                <LocalFireDepartment
                  sx={{
                    color: "error.light",
                    mx: 1,
                    fontSize: { xs: 20, sm: 28 },
                  }}
                />
              </Box>
              <Avatar sx={{ m: 1, backgroundColor: "primary.dark" }}>
                <LockOutlinedIcon
                  sx={{
                    color: "info.main",
                  }}
                />
              </Avatar>
              <Box>{texts.login}</Box>

              <Box
                sx={{
                  mt: 1,
                  width: { xs: 270, sm: 300, md: 350, lg: 400, xl: 450 },
                }}
              >
                {validatorError !== "" && (
                  <Box
                    sx={{
                      width: 1,
                      mb: 2,
                    }}
                  >
                    <Alert
                      sx={{
                        mt: 2,
                        backgroundColor: "error.dark",
                      }}
                      severity="error"
                      iconMapping={{
                        error: (
                          <ErrorOutlineOutlined
                            fontSize="inherit"
                            sx={{
                              color: "error.light",
                            }}
                          />
                        ),
                      }}
                      variant="outlined"
                    >
                      {validatorError}
                    </Alert>
                  </Box>
                )}
                <CustomInput
                  label={texts.email}
                  name="email"
                  required={true}
                  shrink={true}
                />
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <CustomInput
                    label={texts.password}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    shrink={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{
                            backgroundColor: "primary.dark",
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>

                <Box
                  sx={{
                    width: 1,
                  }}
                >
                  <CustomSubmitButton
                    isPosting={isPosting}
                    text={texts.login}
                    fullWidth={true}
                  />
                </Box>
                <Grid container>
                  <Grid item xs>
                    <Link
                      sx={{
                        color: colors.slate[300],
                      }}
                      href="#"
                      variant="body2"
                    >
                      {texts.forgot_password}
                    </Link>
                  </Grid>
                </Grid>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{ mt: 5 }}
                >
                  {"Copyright © "}
                  <Link
                    target="_blank"
                    referrerPolicy="no-referrer"
                    color="inherit"
                    href="https://wisnet.com.tr"
                  >
                    Wisnet
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* SIDE::start */}
          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            sx={{
              display: "flex",
              position: "relative",
            }}
          >
            {/* BLURRED OBJECT BACKGROUND::start */}
            <Box
              sx={{
                height: "16rem",
                width: "16rem",
                position: "absolute",
                backgroundColor: colors.orange[800],
                opacity: "70%",
                borderRadius: 100,
                top: "35%",
                right: "50%",
                filter: "blur(24px)",
                mixBlendMode:
                  theme.palette.mode === "dark" ? "color-dodge" : "multiply",
                "@keyframes blob": keyFrames,
                animation: "blob 7s infinite",
                animationDelay: "0s",
              }}
            />
            <Box
              sx={{
                height: "16rem",
                width: "16rem",
                position: "absolute",
                backgroundColor: colors.green[800],
                opacity: "70%",
                borderRadius: 100,
                top: "42%",
                right: "40%",
                filter: "blur(24px)",
                mixBlendMode:
                  theme.palette.mode === "dark" ? "color-dodge" : "multiply",
                "@keyframes blob": keyFrames,
                animation: "blob 7s infinite",
                animationDelay: "2s",
              }}
            />
            <Box
              sx={{
                height: "16rem",
                width: "16rem",
                position: "absolute",
                backgroundColor: colors.fuchsia[800],
                opacity: "70%",
                borderRadius: 100,
                top: "49%",
                right: "30%",
                filter: "blur(24px)",
                mixBlendMode:
                  theme.palette.mode === "dark" ? "color-dodge" : "multiply",
                "@keyframes blob": keyFrames,
                animation: "blob 7s infinite",
                animationDelay: "4s",
              }}
            />
            {/* BLURRED OBJECT BACKGROUND::end */}
            <Box
              sx={{
                height: 1,
                width: 1,
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Box>
          </Grid>
          {/* SIDE::end */}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Login;
