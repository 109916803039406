import React, { createContext, useState, ReactNode, useEffect } from "react";
import { useAuth } from "./AuthProvider";

interface SelectedLocation {
  id: number;
  community: string;
  description: string;
  expire: string;
  nasname: string;
  shortname: string;
  sms_balance: number;
}

interface SelectedLocationContextType {
  selectedLocation: SelectedLocation | undefined;
  changeSelectedLocation: (selectedLocation: SelectedLocation) => void;
}

export const SelectedLocationContext =
  createContext<SelectedLocationContextType>({
    selectedLocation: undefined,
    changeSelectedLocation: () => {},
  });

interface SelectedLocationProviderProps {
  children: ReactNode;
}

const SelectedLocationProvider = ({
  children,
}: SelectedLocationProviderProps) => {
  const { user, logOut } = useAuth();
  const [selectedLocation, setSelectedLocation] = useState<
    SelectedLocation | undefined
  >();

  useEffect(() => {
    if (user) {
      // Get selectedLocation
      const savedLocation = localStorage.getItem("selected-location");
      if (savedLocation) {
        try {
          const savedLocationJSON = JSON.parse(savedLocation);
          setSelectedLocation(savedLocationJSON);
        } catch (error) {
          console.error("Error parsing saved location:", error);
        }
      } else {
        // If no selectedLocation get it from user
        const selectedLocationToSave = user?.data?.locations?.[0];
        if (selectedLocationToSave) {
          setSelectedLocation(selectedLocationToSave);
          localStorage.setItem(
            "selected-location",
            JSON.stringify(selectedLocationToSave)
          );
        } else {
          // If user is empty too
          logOut();
        }
      }
    }
  }, [user, logOut]);

  const changeSelectedLocation = (location: SelectedLocation) => {
    setSelectedLocation(location);
    localStorage.setItem("selected-location", JSON.stringify(location));
  };

  return (
    <SelectedLocationContext.Provider
      value={{ selectedLocation, changeSelectedLocation }}
    >
      {children}
    </SelectedLocationContext.Provider>
  );
};

export { SelectedLocationProvider };
