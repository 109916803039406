import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderUser from "./HeaderUser";
import HeaderLanguage from "./HeaderLanguage";
import HeaderLocations from "./HeaderLocations";
import { SelectedLocationContext } from "../../auth/SelectedLocationContext";

interface Props {
  collapsed: boolean;
  setCollapsed: any;
  broken: boolean;
  setBroken: any;
  toggled: boolean;
  setToggled: any;
}

const Topbar = ({
  collapsed,
  setCollapsed,
  broken,
  setBroken,
  toggled,
  setToggled,
}: Props) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { selectedLocation } = useContext(SelectedLocationContext);
  // User menu
  const [userState, setUserState] = useState<null | HTMLElement>(null);
  const openUser = Boolean(userState);
  const idUser = openUser ? "simple-popper" : undefined;

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserState(event.currentTarget);
  };
  const handleUserClickAway = () => {
    setUserState(null);
  };
  // Language menu
  const [languageState, setLanguageState] = useState<null | HTMLElement>(null);
  const openLanguage = Boolean(languageState);
  const idLanguage = openLanguage ? "simple-popper" : undefined;

  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageState(event.currentTarget);
  };
  const handleLanguageClickAway = () => {
    setLanguageState(null);
  };
  // Locations menu
  const [locationsState, setLocationsState] = useState<null | HTMLElement>(
    null
  );
  const openLocations = Boolean(locationsState);
  const idLocations = openLocations ? "simple-popper" : undefined;

  const handleClickLocations = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLocationsState(event.currentTarget);
  };
  const handleLocationsClickAway = () => {
    setLocationsState(null);
  };
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
    >
      <AppBar
        sx={{
          height: 72,
          boxShadow: 0,
        }}
        position="static"
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            height: 1,
            backgroundColor: "background.default",
            borderBottom: 1,
            borderColor: "primary.light",
          }}
        >
          {/* Small screen sidebar open/close */}
          {broken && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                display: { xs: "flex", lg: "none" },
              }}
              onClick={() => setToggled(!toggled)}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* Small screen sidebar open/close */}

          <Box
            sx={{
              width: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
            >
              {selectedLocation?.shortname} -{" "}
              {selectedLocation?.community
                ? selectedLocation.community
                : selectedLocation?.nasname}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}
            >
              {selectedLocation?.shortname}
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box>
                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon />
                  ) : (
                    <LightModeOutlinedIcon />
                  )}
                </IconButton>
              </Box>
              <ClickAwayListener onClickAway={handleLocationsClickAway}>
                <Box>
                  <IconButton id="basic-button" onClick={handleClickLocations}>
                    <FmdGoodOutlinedIcon />
                  </IconButton>
                  <HeaderLocations
                    open={openLocations}
                    id={idLocations}
                    anchorEl={locationsState}
                    setAnchorEl={setLocationsState}
                  />
                </Box>
              </ClickAwayListener>
              <ClickAwayListener onClickAway={handleLanguageClickAway}>
                <Box>
                  <IconButton id="basic-button" onClick={handleClickLanguage}>
                    <LanguageOutlinedIcon />
                  </IconButton>
                  <HeaderLanguage
                    open={openLanguage}
                    id={idLanguage}
                    anchorEl={languageState}
                    setAnchorEl={setLanguageState}
                  />
                </Box>
              </ClickAwayListener>
              <ClickAwayListener onClickAway={handleUserClickAway}>
                <Box>
                  <IconButton id="basic-button" onClick={handleClickUser}>
                    <PersonOutlinedIcon />
                  </IconButton>
                  <HeaderUser
                    open={openUser}
                    id={idUser}
                    anchorEl={userState}
                    setAnchorEl={setUserState}
                  />
                </Box>
              </ClickAwayListener>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Topbar;
