import React from "react";
import { TextFieldElement } from "react-hook-form-mui";

interface Props {
  label?: string;
  name: string;
  type?: string;
  required?: boolean;
  autoFocus?: boolean;
  startAdornment?: any;
  endAdornment?: any;
  shrink?: boolean;
  placeholder?: string;
}

const CustomInput = React.memo(
  ({
    name,
    label,
    required,
    autoFocus,
    type,
    startAdornment,
    endAdornment,
    shrink,
    placeholder,
  }: Props) => {
    return (
      <TextFieldElement
        InputLabelProps={{
          style: {
            fontFamily: "Public Sans, sans-serif", // Label font ailesini belirt
          },
          shrink: shrink,
        }}
        fullWidth
        id={name}
        name={name}
        label={label}
        placeholder={placeholder}
        type={type}
        required={required === true ? true : false}
        autoFocus={autoFocus === true ? true : false}
        // Password aç/kapat için
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
        }}
        sx={{
          "& .MuiFormHelperText-root": {
            margin: 0,
            p: 1,
          },
          "& .MuiInputBase-input": {
            paddingRight: "16px", // Adds padding-right to the input field
          },
        }}
      />
    );
  }
);

export default CustomInput;
