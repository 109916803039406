import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from "react";
import * as authHelper from "./AuthHelpers";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { tokens } from "../theme";
interface ILocations {
  id: number;
  community: string;
  description: string;
  expire: string;
  nasname: string;
  shortname: string;
  sms_balance: number;
}

interface AuthData {
  locations: ILocations[];
  user: {};
}
interface AuthModel {
  is_true: boolean;
  access_token: string;
  server_url: string;
  data: AuthData;
  expire: string;
}

const AuthContext = createContext<any>(undefined);
const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [user, setUser] = useState<AuthModel | undefined>(undefined);
  const saveAuth = async (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      // Auth olmadığında data'yı local storage'dan sil
      authHelper.removeAuth();
    }
  };

  const logOut = () => {
    saveAuth(undefined);
    setUser(undefined);
    localStorage.removeItem("selected-location");
  };

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, user, setUser, logOut, saveAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthInit = ({ children }: any) => {
  const { auth, setUser, logOut } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (access_token: string) => {
      try {
        if (!didRequest.current) {
          const user = localStorage.getItem("user");
          if (user) {
            console.log("buldu");
            const userJSON = JSON.parse(user);
            const data = userJSON;
            if (data) {
              setUser(data);
            }
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logOut();
        }
      } finally {
        setTimeout(() => {
          setShowSplashScreen(false);
        }, 1000);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.access_token) {
      requestUser(auth.access_token);
    } else {
      logOut();
      setShowSplashScreen(false);
      console.log("else");
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? (
    <Box
      sx={{
        height: "100vh",
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      <CircularProgress
        size={30}
        sx={{
          color: colors.slate[400],
        }}
      />
    </Box>
  ) : (
    <>{children}</>
  );
};

export { useAuth, AuthProvider, AuthInit };
