import {
  KeyboardArrowRightOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { hexToRgba } from "../../utils/HexToRgba";
import { useAuth } from "../../auth/AuthProvider";
import { SelectedLocationContext } from "../../auth/SelectedLocationContext";
import { LanguageContext } from "../../language/LanguageContext";
interface ILocations {
  id: number;
  community: string;
  description: string;
  expire: string;
  nasname: string;
  shortname: string;
  sms_balance: number;
}
const HeaderLocations = ({ open, id, anchorEl }: any) => {
  const theme = useTheme();

  const { user } = useAuth();
  const { texts } = useContext(LanguageContext);
  const { changeSelectedLocation } = useContext(SelectedLocationContext);
  const test = {
    id: 1,
    community: "1",
    description: "1",
    expire: "1",
    nasname: "1",
    shortname: "1",
    sms_balance: 1,
  };
  const locations: ILocations[] = [...user.data.locations, test];

  const handleChangeLocation = (item: any) => {
    changeSelectedLocation(item);
  };

  const [filter, setFilter] = useState<string>(""); // Filtreleme terimini saklamak için state

  // Filtreleme işlevi
  const filteredItems = locations.filter(
    (item) =>
      item.shortname.toLowerCase().includes(filter.toLowerCase()) ||
      item.nasname.toLowerCase().includes(filter.toLowerCase()) ||
      item.community.toLowerCase().includes(filter.toLowerCase()) ||
      (item.description &&
        item.description.toLowerCase().includes(filter.toLowerCase()))
  );

  const handleSearch = (e: any) => {
    setFilter(e.target.value);
  };
  return (
    <Popper
      id={id}
      anchorEl={anchorEl}
      open={open}
      sx={{
        mt: "17px !important",
        width: { xs: "100%", sm: "350px", lg: "375px" },
        boxShadow: 10,
        overflow: "hidden",
        backgroundColor: "background.default", // Background color
        opacity: 1,
        backgroundImage: "none",
        border: "1px solid",
        borderColor: "border.main",
        borderRadius: 1,
        zIndex: 7, //Loginde 2 verildiğinden tıklanmıyordu
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 1,
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          autoFocus
          placeholder={texts.search_location}
          value={filter}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Divider />
        {/* Locations container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxHeight: "450px",
            overflowY: "auto",
          }}
        >
          {filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              // Location
              <ButtonBase
                sx={{
                  width: 1,
                  backgroundColor: hexToRgba(theme.palette.background.paper, 1),
                  ":hover": {
                    backgroundColor: hexToRgba(theme.palette.info.main, 0.1),
                  },
                  borderRadius: 2,
                }}
                key={index}
                onClick={() => handleChangeLocation(item)}
              >
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                    textAlign: "start",
                    p: 2,
                    display: "flex",
                  }}
                >
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.shortname}
                    </Typography>
                    <Typography
                      sx={{
                        opacity: "0.6",
                        pt: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Chip
                      label={item.community ? item.community : item.nasname}
                      variant="outlined"
                      sx={{
                        backgroundColor: theme.palette.background.default,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <KeyboardArrowRightOutlined />
                  </Grid>
                </Grid>
              </ButtonBase>
            ))
          ) : (
            <Box
              sx={{
                mb: 1,
                py: 2,
                pl: 2,
                backgroundColor: hexToRgba(theme.palette.info.main, 0.1),
                borderRadius: 2,
              }}
            >
              {texts.location_not_found}
            </Box>
          )}
        </Box>
      </Box>
    </Popper>
  );
};

export default HeaderLocations;
