import React from "react";
import { Outlet } from "react-router-dom";
import { AuthInit } from "./auth/AuthProvider";
import { LanguageProvider } from "./language/LanguageContext";
import { SelectedLocationProvider } from "./auth/SelectedLocationContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@mui/material";
import { tokens } from "./theme";

const App: React.FC = React.memo(() => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <LanguageProvider>
      <AuthInit>
        <SelectedLocationProvider>
          <ToastContainer
            position="top-center"
            hideProgressBar={true}
            toastStyle={{
              backgroundColor: theme.palette.toastColor.main,
              color: theme.palette.toastColor.contrastText,
              border: "1px solid",
              borderColor: theme.palette.border.main,
              cursor: "pointer",
            }}
          />
          <Outlet />
        </SelectedLocationProvider>
      </AuthInit>
    </LanguageProvider>
  );
});

export default App;
