import { Box, MenuItem, Popper } from "@mui/material";
import React, { useContext } from "react";
import { useAuth } from "../../auth/AuthProvider";
import { LanguageContext } from "../../language/LanguageContext";

const HeaderUser = ({ open, id, anchorEl, setAnchorEl }: any) => {
  const { logOut } = useAuth();
  const { texts } = useContext(LanguageContext);

  const handleLogout = () => {
    logOut();
  };

  return (
    <Popper
      id={id}
      anchorEl={anchorEl}
      open={open}
      sx={{
        mt: "17px !important",
        overflow: "hidden",
        backgroundColor: "background.default", // Background color
        opacity: 1,
        backgroundImage: "none",
        border: "1px solid",
        borderColor: "border.main",
        borderRadius: 1,
        zIndex: 7, //Loginde 2 verildiğinden tıklanmıyordu
      }}
      placement="bottom-end"
    >
      <Box>
        <MenuItem
          sx={{
            py: 2,
          }}
        >
          {texts.change_password}
        </MenuItem>
        <MenuItem
          sx={{
            py: 2,
          }}
          onClick={handleLogout}
        >
          {texts.logout}
        </MenuItem>
      </Box>
    </Popper>
  );
};

export default HeaderUser;
