import {
  Box,
  ButtonBase,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import { toAbsoluteUrl } from "../../utils/AssetHelpers";
import { hexToRgba } from "../../utils/HexToRgba";
import SideBarItems from "../../const/SideBarItems";

interface Props {
  collapsed: boolean;
  setCollapsed: any;
  broken: boolean;
  setBroken: any;
  toggled: boolean;
  setToggled: any;
}

const MySidebar = ({
  collapsed,
  setCollapsed,
  broken,
  setBroken,
  toggled,
  setToggled,
}: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const location = useLocation();
  const [url, setUrl] = useState(location.pathname);
  console.log("/" + url.split("/")[1] + "/" + url.split("/")[2] + "/");
  console.log("loc", location);

  // SideBarItems - PrivateRouteItems ile aynı klasöre almaya çalışırsam texts tekrar çağırdığından component rerender oluyor.

  const pages = SideBarItems();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const themes = {
    light: {
      sidebar: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.textColor.main,
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#0098e5",
        hover: {
          backgroundColor: "#c5e4ff",
          color: "#44596e",
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.textColor.main,
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };
  // Menu styles
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    icon: {
      color: themes[theme.palette.mode].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme.palette.mode].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: theme.palette.info.main,
      paddingInlineEnd: "8px",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? theme.palette.background.default : "transparent",
    }),
    button: {
      borderColor: `${theme.palette.border?.main} !important`,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme.palette.mode].menu.disabled.color,
      },
      [`&.${menuClasses.active}`]: {
        backgroundColor: theme.palette.button.main,
        color: theme.palette.info.main,
        borderColor: `${colors.blue[500]} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.button.hover,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 400 : 400,
    }),
  };

  return (
    <Box
      id="idk"
      sx={{
        display: "flex",
        height: 1,
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 50,
      }}
    >
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme.palette.mode].sidebar.backgroundColor,
          1
        )}
        rootStyles={{
          color: themes[theme.palette.mode].sidebar.color,
          overflow: "hidden",
        }}
        style={{
          borderRight: "1px solid",
          borderColor: theme.palette.border?.main,
        }}
      >
        <Menu menuItemStyles={menuItemStyles}>
          <Box
            sx={{
              ml: 1,
              height: 74,
            }}
          >
            <Box
              sx={{
                height: 72,
                backgroundColor: "background.default",
                cursor: "default",
                display: "flex",
                alignItems: "center",
                borderBottom: 1,
                borderColor: "primary.light",
                ":hover": {
                  opacity: "1 !important",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: 20,
                  fontWeight: "500",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <IconButton>
                    <img
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                      src={toAbsoluteUrl("/media/logos/netway.svg")}
                      alt=""
                    />
                  </IconButton>
                  <Typography variant="h4">Netway</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              py: 1,
              overflowY: "scroll", // Yalnızca dikey kaydırma çubuğu ekler
              maxHeight: "calc(100vh - 74px)", // Bu değeri üst ve alt boşluklara göre ayarlayın
            }}
          >
            {/* ITEMS::start */}
            {pages.map((item, itemIndex) => {
              return item.subItems ? (
                // Url eşleşiyorsa submenu'yü aç
                <SubMenu
                  key={itemIndex}
                  defaultOpen={
                    "/" + url.split("/")[1] === item.url ? true : false
                  }
                  icon={item.icon}
                  label={item.text}
                  style={{
                    margin: "0px 8px",
                    padding: "0px 8px 0px 0px",
                  }}
                >
                  <Box>
                    {item.subItems.map((subItem, subItemIndex) => (
                      <Box
                        key={subItemIndex}
                        sx={{
                          px: 1,
                          ml: collapsed ? 1 : 0,
                        }}
                      >
                        <ButtonBase
                          sx={{
                            width: 1,
                            textAlign: "left",
                            fontSize: "13px",
                            backgroundColor: "background.paper",
                          }}
                        >
                          <MenuItem
                            icon={subItem.icon}
                            active={new RegExp(
                              `^${item.url}${subItem.url.replace(/\/\d+$/, "")}`
                            ).test(url)}
                            component={
                              <Link to={`${item.url}${subItem.url}`} />
                            }
                            style={{
                              borderLeft: "2px solid",
                              padding: "0px 5px 0px 0px",
                            }}
                          >
                            <Typography
                              sx={{
                                mt: 0.5,
                                color:
                                  url === item.url
                                    ? colors.blue[500]
                                    : undefined,
                                fontSize: "13px",
                              }}
                            >
                              {subItem.text}
                            </Typography>
                          </MenuItem>
                        </ButtonBase>
                      </Box>
                    ))}
                  </Box>
                </SubMenu>
              ) : (
                <Box
                  key={itemIndex}
                  sx={{
                    px: 1,
                  }}
                >
                  <ButtonBase
                    sx={{
                      width: 1,
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                  >
                    <MenuItem
                      icon={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.icon}
                        </Box>
                      }
                      active={url === item.url}
                      component={<Link to={item.url} />}
                      style={{
                        padding: "0px 16px 0px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 0.5,
                          color:
                            url === item.url
                              ? theme.palette.info.main
                              : undefined,
                        }}
                      >
                        {item.text}
                      </Typography>
                    </MenuItem>
                  </ButtonBase>
                </Box>
              );
            })}
            {/* ITEMS::end */}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MySidebar;
