import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import en from "./locales/en.json";
import tr from "./locales/tr.json";

interface LanguageFiles {
  [key: string]: { [key: string]: string };
}

interface LanguageContextType {
  language: string;
  texts: { [key: string]: string };
  changeLanguage: (lang: string) => void;
}

const languageFiles: LanguageFiles = {
  en,
  tr,
};

export const LanguageContext = createContext<LanguageContextType>({
  language: "en",
  texts: languageFiles["en"],
  changeLanguage: () => {},
});

interface LanguageProviderProps {
  children: ReactNode;
}

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState<string>("en");
  const [texts, setTexts] = useState<{ [key: string]: string }>(
    languageFiles[language]
  );

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selected-language");
    if (savedLanguage && languageFiles[savedLanguage]) {
      setLanguage(savedLanguage);
      setTexts(languageFiles[savedLanguage]);
    } else {
      const browserLanguage = navigator.language.split("-")[0];
      const initialLanguage = languageFiles[browserLanguage]
        ? browserLanguage
        : "en";
      setLanguage(initialLanguage);
      setTexts(languageFiles[initialLanguage]);
      localStorage.setItem("selected-language", initialLanguage);
    }
  }, []);

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    setTexts(languageFiles[lang]);
    localStorage.setItem("selected-language", lang);
  };

  const providerValue = useMemo(
    () => ({ language, texts, changeLanguage }),
    [language, texts]
  );

  return (
    <LanguageContext.Provider value={providerValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };
