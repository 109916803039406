import { MenuItem, Popper } from "@mui/material";
import React, { useContext } from "react";
import { LanguageContext } from "../../language/LanguageContext";

const HeaderLanguage = ({ open, id, anchorEl, setAnchorEl }: any) => {
  const { changeLanguage } = useContext(LanguageContext);

  const handleSelect = (e: string) => {
    changeLanguage(e);
  };

  return (
    <Popper
      id={id}
      anchorEl={anchorEl}
      open={open}
      sx={{
        mt: "17px !important",
        overflow: "hidden",
        backgroundColor: "background.default", // Background color
        opacity: 1,
        backgroundImage: "none",
        border: "1px solid",
        borderColor: "border.main",
        borderRadius: 1,
        zIndex: 7, //Loginde 2 verildiğinden tıklanmıyordu
      }}
      placement="bottom-end"
    >
      <MenuItem
        sx={{
          py: 2,
        }}
        onClick={() => handleSelect("en")}
      >
        English - EN
      </MenuItem>
      <MenuItem
        sx={{
          py: 2,
        }}
        onClick={() => handleSelect("tr")}
      >
        Türkçe - TR
      </MenuItem>
    </Popper>
  );
};

export default HeaderLanguage;
