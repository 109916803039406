import React, { useState } from "react";
import Topbar from "./topbar/Topbar";
import { Outlet } from "react-router-dom";
import MySidebar from "./aside/MySidebar";
import { Box } from "@mui/material";

const MasterLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [broken, setBroken] = useState(false);
  const [toggled, setToggled] = useState(false);

  return (
    <div className="app">
      <MySidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        broken={broken}
        setBroken={setBroken}
        toggled={toggled}
        setToggled={setToggled}
      />
      <main className="content">
        <Topbar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          broken={broken}
          setBroken={setBroken}
          toggled={toggled}
          setToggled={setToggled}
        />

        <Box
          className="main-box"
          sx={{
            p: { xs: 1, md: 2, lg: 2 },
            backgroundColor: "secondary.main",
          }}
        >
          <Box className="inner-box">
            {/* Pages::start */}
            <Outlet />
            {/* Pages::end */}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default MasterLayout;
