import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LanguageContext } from "../../language/LanguageContext";
import { tokens } from "../../theme";

interface Props {
  isPosting: boolean;
  text?: any;
  fullWidth?: boolean;
}

const CustomSubmitButton = ({ isPosting, text, fullWidth }: Props) => {
  const { texts } = useContext(LanguageContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
      <Button
        disabled={isPosting}
        type="submit"
        variant="contained"
        fullWidth={fullWidth}
        sx={{
          color: colors.slate[800],
          backgroundColor: colors.gray[100],
          ":hover": {
            backgroundColor: colors.gray[200],
          },
          ":disabled": {
            backgroundColor: colors.gray[700],
          },
        }}
      >
        {isPosting ? (
          <CircularProgress
            size={20}
            sx={{
              color: colors.slate[400],
            }}
          />
        ) : text ? (
          text
        ) : (
          texts.save
        )}
      </Button>
    </Box>
  );
};

export default CustomSubmitButton;
